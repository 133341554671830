import { Box, Flex, Grid } from 'components/box';
import { css } from '@emotion/react';
import { Heading } from 'components/typography';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'components/configurable-routing';
import { productHoverStyle } from 'components/_shared/widgets/gallery/shared';
import Loading from 'components/loading';
import config from '../../../../util/load-config';
import ResponsiveImage, {
  mediaBreakpoints,
} from 'components/_shared/elements/responsive-image';
import ErrorBoundary from 'components/_global/error-boundary';
import { useHasGlobalStatus, useAddGlobalStatus } from 'hooks/app';
import {
  isSailthruValid,
  SAILTHRU_STATUS_INITIALIZED,
  SAILTHRU_STATUS_PERSONALIZED,
} from 'components/_global/sailthru';
import { trackProductClickEvent } from '../../../../util/tag-manager';
import { CURRENCY_CODE } from '../../../../constants';

interface SailthruProductInterface {
  images: {
    full: {
      url: string;
    };
  };
  price: number;
  url: string;
  vars: {
    displayRetailIfSavings?: string;
    brand: string;
    full_name: string;
    retail?: string;
    has_multiple_prices: string;
  };
  title?: string;
}

type SailthruSection = SailthruProductInterface[] | null;

interface SailthruRelatedProducts {
  related_items: SailthruSection;
  you_may_also_like: SailthruSection;
}

const isValidSailthruProduct = (
  product: SailthruProductInterface | unknown
): product is SailthruProductInterface =>
  typeof (product as SailthruProductInterface).price === 'number' &&
  typeof (product as SailthruProductInterface).url === 'string' &&
  typeof (product as SailthruProductInterface).images !== 'undefined' &&
  typeof (product as SailthruProductInterface).images.full !== 'undefined' &&
  typeof (product as SailthruProductInterface).images.full.url === 'string' &&
  typeof (product as SailthruProductInterface).vars !== 'undefined' &&
  typeof (product as SailthruProductInterface).vars.brand === 'string' &&
  typeof (product as SailthruProductInterface).vars.full_name === 'string' &&
  typeof (product as SailthruProductInterface).vars.has_multiple_prices ===
    'string';

const isValidSailthruSection = (
  section: SailthruSection | unknown
): section is SailthruSection =>
  typeof (section as SailthruSection) !== 'undefined' &&
  ((section as SailthruSection) === null ||
    (Array.isArray(section as SailthruSection) &&
      ((section as SailthruSection) || []).every(isValidSailthruProduct)));

const isValidSailthruResponse = (
  res: SailthruRelatedProducts | unknown
): res is SailthruRelatedProducts =>
  // not falsy response
  !!res &&
  // related products
  isValidSailthruSection((res as SailthruRelatedProducts).related_items) &&
  // you may also like
  isValidSailthruSection((res as SailthruRelatedProducts).you_may_also_like);

const formatDisplayPrice = (price: number) => {
  return `R${(price / 100).toLocaleString('en-us')}`;
};

const parseRetailSaving = (price: string) => {
  const formatPrice = price.replace(/\D/g, '');
  return `R${parseInt(formatPrice, 10).toLocaleString('en-us')}`;
};

const imageWidths = [125, 200, 300];

const imageSizes = [
  `${mediaBreakpoints[0]} 33vw`,
  `${mediaBreakpoints[1]} 25vw`,
  `${imageWidths[2]}px`,
];

const trackProductClick = (product: SailthruProductInterface) =>
  trackProductClickEvent({
    product: {
      item_name: product.vars.full_name,
      price: product.price,
      item_brand: product.vars.brand,
      currency: CURRENCY_CODE,
    },
  });

const productTitleStyle = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const RelatedProduct = ({ product }: { product: SailthruProductInterface }) => (
  <Box
    mr={3}
    minWidth="115px"
    width="100%"
    css={css`
      &:nth-last-of-type(2) {
        margin-right: 0;
      }
    `}
  >
    <Grid
      gridRowGap={1}
      gridColumnGap={0}
      css={css`
        height: 100%;
        /* IOS 12is blank scroll mitigation*/
        -webkit-transform: translate3d(0, 0, 0);
        -webkit-perspective: 1000;
      `}
    >
      <Link
        dynamicUrl="/products/[id]"
        href={product.url.replace('https://www.onedayonly.co.za', '')}
        onClick={() => trackProductClick(product)}
        css={theme => css`
          backface-visibility: hidden;

          ${productHoverStyle(theme)};
        `}
      >
        <Flex
          css={theme => css`
            border-radius: ${theme.radii[3]}px;
            overflow: hidden;
            box-shadow: ${theme.shadows.products};
            transition: all 0.2s ease;

            will-change: transform;

            &:after {
              content: '';
              display: block;
              padding-bottom: 100%;
            }
          `}
        >
          <ResponsiveImage
            title={product.vars.full_name}
            url={product.images.full.url}
            widths={imageWidths}
            sizes={imageSizes}
          />
        </Flex>

        <Grid
          gridTemplateRows="auto 1fr"
          gridColumnGap={0}
          gridRowGap={0}
          mt={3}
          css={css`
            overflow: hidden;
          `}
        >
          <Heading
            className="highlightOnHover"
            fontSize={['0.9rem', '1.1rem', 2]}
            fontWeight="bold"
            lineHeight="1.2em"
            color="black"
            css={productTitleStyle}
          >
            {product.vars.brand}
          </Heading>
          <Heading
            fontWeight="medium"
            fontSize={['0.8rem', '1.1rem', 2]}
            color="darkGrey"
            lineHeight="1.5em"
            css={productTitleStyle}
          >
            {product.title || product.vars.full_name}
          </Heading>
        </Grid>

        <Flex mb={2} alignItems="flex-end" flexWrap="wrap">
          {product.vars.has_multiple_prices === 'y' && (
            <Heading
              fontSize={1}
              color="darkGrey"
              fontWeight="medium"
              mt={2}
              mr={1}
            >
              From
            </Heading>
          )}
          <Heading
            fontWeight={600}
            mr={2}
            mt={2}
            color="black"
            className="highlightOnHover"
            css={css`
              position: relative;
              bottom: -0.05em;
            `}
          >
            {formatDisplayPrice(product.price)}
          </Heading>

          {product.vars.displayRetailIfSavings && (
            <Heading
              fontSize={1}
              color="darkGrey"
              fontWeight="medium"
              mt={2}
              css={css`
                text-decoration: line-through;
              `}
            >
              {parseRetailSaving(product.vars.displayRetailIfSavings)}
            </Heading>
          )}
        </Flex>
      </Link>
    </Grid>
  </Box>
);

const RelatedProductsGallery = ({
  title,
  id,
  products,
}: {
  title: string;
  id: string;
  products?: SailthruProductInterface[];
}) => (
  <Box
    mt={4}
    pt={4}
    id={id}
    css={theme => css`
      grid-area: related;
      border-top: 1px solid ${theme.colors.darkishGrey};
    `}
  >
    <Heading fontSize={[3, 4]} mb="1em">
      {title}
    </Heading>

    {!products && (
      <Loading isLoading size="small">
        <Box
          css={css`
            height: 30px;
          `}
        />
      </Loading>
    )}

    {products && (
      <Flex
        overflowX={['scroll', 'hidden']}
        height="100%"
        flexDirection="row"
        px={[3, 4]}
        m={['-16px -16px 0 -16px', '-16px -32px 0 -32px']}
        pt={3}
        css={css`
          ::-webkit-scrollbar {
            display: none;
          }
        `}
      >
        {products.map((product, idx) => (
          <RelatedProduct product={product} key={idx} />
        ))}

        <Box
          /**
           * Yes, this looks stupid.
           * The last product touches the edge of the screen when scrolling through the products.
           * Margins, padding and pseudo-elements were unable to solve this problem, hence this box used
           * purely for spacing.
           */

          className="show-for-mobile-only"
          css={css`
            min-width: 16px;
          `}
        />
      </Flex>
    )}
  </Box>
);

interface CustomizableRelatedProductsProps {
  hideRelated?: boolean;
  hideYouMayAlsoLike?: boolean;
  customTitle?: string;
}

const RelatedProducts = ({
  contextKey,
  sailthruErrorCallback,
  hideRelated,
  hideYouMayAlsoLike,
  customTitle,
}: {
  contextKey: string;
  sailthruErrorCallback: () => void;
} & CustomizableRelatedProductsProps) => {
  const sailthruPersonalizedRef = useRef(false);
  const addGlobalStatus = useAddGlobalStatus();
  const isSailthruInitialized = useHasGlobalStatus(SAILTHRU_STATUS_INITIALIZED);

  const [sailthruProducts, setSailthruProducts] = useState<
    SailthruRelatedProducts | undefined
  >();

  /**
   * Personalize with sailthru.
   * @see https://getstarted.sailthru.com/site/site-personalization-manager/implementation/
   */
  useEffect(() => {
    if (
      !sailthruPersonalizedRef.current &&
      isSailthruInitialized &&
      window.Sailthru &&
      isSailthruValid(window.Sailthru)
    ) {
      window.Sailthru.personalize({
        vars: { context_key: contextKey },
        sections: [
          {
            id: config.sailthru.personalizeId,
            onSuccess: res => {
              const json = JSON.parse(res.json);
              if (isValidSailthruResponse(json)) {
                setSailthruProducts(json);
              }
            },
            onError: () => sailthruErrorCallback(),
          },
        ],
      });

      addGlobalStatus(SAILTHRU_STATUS_PERSONALIZED);
      sailthruPersonalizedRef.current = true;
    }
  }, [
    isSailthruInitialized,
    contextKey,
    sailthruErrorCallback,
    addGlobalStatus,
  ]);

  /**
   * Error out if we get back absolutely no products.
   */
  useEffect(() => {
    if (
      sailthruProducts &&
      (sailthruProducts?.related_items || []).length === 0 &&
      (sailthruProducts?.you_may_also_like || []).length === 0
    ) {
      sailthruErrorCallback();
    }
  }, [sailthruErrorCallback, sailthruProducts]);

  return (
    <>
      {!hideYouMayAlsoLike &&
        sailthruProducts?.you_may_also_like &&
        sailthruProducts?.you_may_also_like.length > 0 && (
          <RelatedProductsGallery
            id="you-may-also-like"
            title={customTitle || 'You may also like'}
            products={sailthruProducts?.you_may_also_like}
          />
        )}

      {!hideRelated &&
        sailthruProducts?.related_items &&
        sailthruProducts?.related_items.length > 0 && (
          <RelatedProductsGallery
            id="related-products"
            title={customTitle || 'Related products'}
            products={sailthruProducts?.related_items}
          />
        )}
    </>
  );
};

const Wrapper = (props: CustomizableRelatedProductsProps) => {
  const [hasError, setHasError] = useState(false);
  const [contextKey, setContextKey] = useState<string | undefined>();

  useEffect(() => {
    setContextKey(window.location.href);
  }, []);

  if (!contextKey || hasError) {
    return null;
  }

  return (
    <ErrorBoundary>
      <RelatedProducts
        {...props}
        contextKey={contextKey}
        sailthruErrorCallback={() => setHasError(true)}
      />
    </ErrorBoundary>
  );
};

export default Wrapper;
